$('document').ready(function(){
    // progress Circle
    $('.dial').each(function() {
        var $this = $(this);
        var myVal = $this.attr("rel");
        // alert(myVal);
        $this.knob({
            "fgColor": "#f9c100",
            "bgColor": "#eaedf4",
            "readOnly": true
        });
        $({
            value: 0
        }).animate({
            value: myVal
        }, {
            duration: 15000,
            easing: 'swing',
            step: function() {
                $this.val(Math.ceil(this.value)).trigger('change');
            },
            complete: function () {
               /* $('.step_1').animate({
                    opacity: "toggle"
                }, 500, "swing", function () {
                    $('.step_2').animate({
                        opacity: "toggle"
                    }, 500, "swing");
                });*/
            }
        })
    });
});
